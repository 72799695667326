import React, { useRef, useEffect, useState, useContext } from 'react';
import { ExperienceContext, useStoreId, useStore } from '@thd-nucleus/experience-context';
import { extend, useDataModel } from '@thd-nucleus/data-sources';
import { bool as boolType, any, string as stringType, number as numType } from 'prop-types';
import { Price } from '@thd-olt-component-react/price';
import { Typography } from '@one-thd/sui-atomic-components';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import './feature-based-recommendations.scss';
import { FBRTableView } from './FBRTableView';
import { propTypes, defaultProps, impressionContainerNameParser } from './fbr.util';
import { FBRPlaceholder } from './FBRPlaceholder/FBRPlaceholder';

import { dataModel } from './dataModel';
//  Component to get data "product" and "fbr" data.
const FBRLoader = ({ itemId, children }) => {
  const storeId = useStoreId({ online: false });
  const store = useStore();
  const { membershipInformation, storeZip: zipCode } = store;
  const loadedStoreId = useRef(storeId);
  const {
    data: fbrDataset, loading, error, called, variables
  } = useDataModel('fbr', {
    variables: {
      itemId,
      storeId,
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
      zipCode
    },
  });

  if (fbrDataset && !loading && called) {
    loadedStoreId.current = variables?.storeId;
  }

  const hasFbr = !!fbrDataset?.fbr;
  if (!fbrDataset && loading) {
    return <FBRPlaceholder />;
  }
  if (error || !fbrDataset || !hasFbr) {
    return null;
  }
  if (!fbrDataset.fbr || !fbrDataset.fbr?.fbrItems || !fbrDataset.fbr?.fbrItems.length) {
    return null;
  }
  return children({
    fbrDataset, zipCode, loading, error, hasFbr, loadedStoreId: loadedStoreId.current, storeId, membershipInformation
  });
};

FBRLoader.propTypes = {
  itemId: stringType,
  // eslint-disable-next-line react/forbid-prop-types
  children: any
};

FBRLoader.defaultProps = {
  itemId: '',
  children: null
};

const NRTimer = ({ startTime }) => {
  useEffect(() => {
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
      const endTime = new Date();
      LIFE_CYCLE_EVENT_BUS.trigger('performance.component-load', {
        componentName: 'FBR',
        startTime,
        endTime: endTime.getTime()
      });
    }
  }, []);
  return null;
};

NRTimer.propTypes = {
  startTime: numType
};

NRTimer.defaultProps = {
  startTime: 0
};
// fix for caching
// Price is deeply nested and not creating queryAttributes on the query provider
// on particluar calls (caching related)
NRTimer.dataModel = extend({}, Price);

const FeatureBasedRecommendations = (props) => {
  const { onHover, onClick, impressionData, name } = props;
  let { itemId } = props;
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const [startTime, setStartTime] = useState();
  const fbrTitle = 'Compare Similar Items';

  useEffect(() => {
    setStartTime(new Date().getTime());
  }, []);

  return (
    <FBRLoader
      itemId={itemId}
      productDomains={['identifiers', 'media', 'pricing', 'reviews', 'info', 'badges']}
    >
      {({
        fbrDataset, storeId, membershipInformation, zipCode
      }) => {
        const { fbrItems, recommendedFeatureSet = '', recommendedProductSet = '' } = fbrDataset?.fbr || {};
        const recommendedFeaturesDataset = {};
        // eslint-disable-next-line no-unused-expressions
        fbrItems?.forEach((fbrItem) => {
          const id = fbrItem?.product?.itemId;
          // eslint-disable-next-line no-unused-expressions
          fbrItem?.recommendedFeatures?.map((feature) => {
            if (!recommendedFeaturesDataset[feature?.name]) {
              recommendedFeaturesDataset[feature?.name] = {};
            }
            recommendedFeaturesDataset[feature?.name][id] = feature?.value;
            return feature;
          });
        });
        let sortedItems = fbrItems.slice().sort((first, next) => {
          if (first.product.itemId === itemId) return -1;
          if (next.product.itemId === itemId) return 1;
          return 0;
        });

        let currentItemIndex = sortedItems.findIndex((item) => {
          return String(item.product.identifiers.itemId) === itemId;
        });

        let paddingClass = isMobile ? 'sui-p-4 sui-pr-0 mobile-view' : 'sui-p-4';

        return (
          <ImpressionProvider
            data={{
              id: impressionData?.id || '',
              component: impressionData?.component || 'FeatureBasedRecommendations',
              name: impressionContainerNameParser(fbrTitle),
              type: impressionData?.type || 'product',
              recType: '',
              position: '',
              category: '',
              categoryPostion: '',
              ...(name ? { sectionSlotID: name } : {}),
            }}
          >
          <>
            <div className={paddingClass}>
              <Typography variant="h2" weight="bold"> {fbrTitle}</Typography>
              <div className="grid sui-pt-8" data-type="container">
                <meta data-prop="name" content="pip_fbr" />
                <FBRTableView
                  storeId={storeId}
                  scheme="fbr"
                  zipCode={zipCode}
                  itemId={itemId}
                  data={sortedItems}
                  currentItemIndex={currentItemIndex}
                  onclick={onClick}
                  onHover={onHover}
                  tableData={recommendedFeaturesDataset}
                  membershipInformation={membershipInformation}
                  recommendedFeatureSet={recommendedFeatureSet}
                  recommendedProductSet={recommendedProductSet}
                />
              </div>
            </div>
            <NRTimer startTime={startTime} />
          </>
          </ImpressionProvider>
        );
      }}
    </FBRLoader>
  );
};

FeatureBasedRecommendations.propTypes = propTypes;

FeatureBasedRecommendations.defaultProps = defaultProps;

FeatureBasedRecommendations.dataModel = dataModel;
FeatureBasedRecommendations.displayName = 'FeatureBasedRecommendations';

export { FeatureBasedRecommendations };